import React, { Fragment, useState, useEffect } from "react"
import { navigate, injectIntl } from "gatsby-plugin-intl"
import { ModalProvider } from "styled-react-modal"
import { ThemeProvider, css, Global } from "@emotion/react"

import { Wrapper } from "./styled"
import theme from "../../utils/theme"

import CountryDetectionPopin from "./CountryDetectionPopin"
import NewsLetterWeekendSpaPopup from "../NewsLetterWeekendSpaPopup"
import StoreContext, { defaultStoreContext } from "../../context/StoreContext"
import UserContext, { defaultUserContext } from "../../context/UserContext"
import { useMutation, ApolloProvider, useLazyQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { apolloClient, isBrowser } from "../../context/ApolloContext"
import {
  setCustomerDetails,
  getCustomerDetailsObj,
  setCheckoutID,
  getCheckoutID,
  handleLogoutLocalStorage,
  setCookie,
  getCookie,
} from "../../utils/localStorageHelper"
import { userInfoEvent } from "../../utils/additionalScriptHelpers"
import { Helmet } from "react-helmet"
import CountryContext, {
  defaultCountryContext,
} from "../../context/CountryContext"
import { useLocation } from "@reach/router"
import FaqSearchSection from "./FaqSearchSection"
import { CountryDropOptions } from "../../utils/CountryDetectionDropHelper"
import HeaderSection from "./HeaderSection"
import NewsLetterMultipleStepPopup from "../NewsLetterMultipleStepPopup"

const countries = CountryDropOptions()

const ADD_TODO = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const GET_CUSTOMER = gql`
  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      displayName
      email
      firstName
      lastIncompleteCheckout {
        id
      }
      lastName
      createdAt
    }
  }
`

const ADD_CHECKOUT_TO_USER = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`

const REMOVE_CHECKOUT_FROM_USER = gql`
  mutation checkoutCustomerDisassociateV2($checkoutId: ID!) {
    checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`

const UPDATE_CUSTOMER = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customer {
        id
        displayName
        email
        firstName
        lastIncompleteCheckout {
          id
        }
        lastName
        createdAt
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const ADD_USER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const FORGET_PASSWORD = gql`
  mutation forgorPassword($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const RESET_PASSWORD = gql`
  mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
      customer {
        id
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const Layout = ({ intl, children }) => {
  // Graphql Queries/Mutations
  const [addTodo, accesTokenCreate] = useMutation(ADD_TODO)
  const [addCheckoutToUser, checkoutToUser] = useMutation(ADD_CHECKOUT_TO_USER)
  const [removeCheckoutFromUser, checkoutFromUser] = useMutation(
    REMOVE_CHECKOUT_FROM_USER
  )
  const [addUpdateCustomer, updateCustomerData] = useMutation(UPDATE_CUSTOMER)
  const [createCustomer, createCustomerData] = useMutation(ADD_USER)
  const [forgetPassowrd, forgetPasswordData] = useMutation(FORGET_PASSWORD)
  const [resetPassword, resetPasswordData] = useMutation(RESET_PASSWORD)
  const [isNotCartPage, setisNotCartPage] = useState(true)
  const [isIdealPage, setisIdealPage] = useState(true)
  const [isFaqPage, setisFaqPage] = useState(false)
  const [isNotLyneupExclusifPage, setisNotLyneupExclusifPage] = useState(true)
  const [exclusifPromo, setexclusifPromo] = useState("")
  const [countryPopInHide, setcountryPopInHide] = useState(true)
  const [userCountry, setuserCountry] = useState({
    ...defaultCountryContext,
    handleStore: countryCode => {
      let storeData = countries.filter(
        item => item.country_code === countryCode
      )[0]
      setuserCountry(prevState => {
        return {
          ...prevState,
          country: countryCode,
          store: storeData.store,
          locale: storeData.locale,
        }
      })
    },
  })
  // State
  const [user, setuser] = useState({
    ...defaultUserContext,
    handleLogin: (username, password, callback) => {
      setuser(prevState => {
        return { ...prevState, loading: true }
      })
      addTodo({
        variables: {
          input: {
            email: username,
            password: password,
          },
        },
      }).then(d => {
        const {
          customerAccessTokenCreate: {
            customerAccessToken,
            customerUserErrors,
          },
        } = d.data
        if (customerUserErrors.length === 0) {
          const { accessToken } = customerAccessToken
          setCustomerDetails("customerAccessToken", accessToken)
          setuser(prevState => {
            return {
              ...prevState,
              customerAccessToken: accessToken,
              loading: false,
            }
          })
          getCustomer()
          callback(d)
        } else {
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
          callback(d)
        }
      })
    },
    updateCustomer: (customer, customerAccessToken) => {
      setuser(prevState => {
        return { ...prevState, loading: true }
      })
      addUpdateCustomer({
        variables: {
          customerAccessToken: customerAccessToken || "",
          customer: customer,
        },
      })
        .then(d => {
          const {
            customerUpdate: {
              customer,
              customerAccessToken,
              customerUserErrors,
            },
          } = d.data
          if (customerUserErrors.length === 0) {
            setCustomerDetails("customerData", customer)
            if (customerAccessToken && customerAccessToken.accesToken)
              setCustomerDetails(
                "customerAccessToken",
                customerAccessToken.accessToken
              )

            setuser(prevState => {
              if (customerAccessToken && customerAccessToken.accesToken) {
                return {
                  ...prevState,
                  customerAccessToken: accesToken,
                  profile: customer,
                  loading: false,
                }
              } else {
                return {
                  ...prevState,
                  profile: customer,
                  loading: false,
                }
              }
            })
          } else {
            setuser(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
            alert(
              customerUserErrors.map(
                item => "ERROR: " + item.code + "\n" + item.message
              )
            )
          }
        })
        .catch(err => {
          console.log(err)
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
        })
    },
    handleLogout: () => {
      handleLogoutLocalStorage()
      setuser({ ...defaultUserContext })
      isBrowser && navigate("/")
    },
    handleSignup: (email, password, callback) => {
      if (email && password) {
        setuser(prevState => {
          return {
            ...prevState,
            loading: true,
          }
        })
        let input = { input: { email: email, password: password } }
        createCustomer({
          variables: input,
        })
          .then(d => {
            callback(d)
            setuser(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
          })
          .catch(err => {
            setuser(prevState => {
              return {
                ...prevState,
                loading: false,
              }
            })
            console.log(err)
          })
      }
    },
    handleForgetPassword: (email, callback) => {
      setuser(prevState => {
        return {
          ...prevState,
          loading: true,
        }
      })
      forgetPassowrd({ variables: { email: email } })
        .then(d => {
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
          if (d.data.customerRecover.customerUserErrors.length === 0) {
            callback(true)
            // alert(intl.formatMessage({ id: "pass__text" }))
          } else {
            callback(false)
            console.log("ERRRO", d.data.customerRecover.customerUserErrors)
            alert(
              d.data.customerRecover.customerUserErrors.map(item => {
                if (item.code === "UNIDENTIFIED_CUSTOMER")
                  return intl.formatMessage({
                    id: "undefined_customer_forgot_password",
                  })
                else return item.message
              })
            )
          }
        })
        .catch(err => {
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
          alert(err)
          console.log(err)
        })
    },
    handleResetPassword: (url, password, callback) => {
      setuser(prevState => {
        return {
          ...prevState,
          loading: true,
        }
      })
      resetPassword({
        variables: { resetUrl: url, password: password },
      })
        .then(d => {
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
          if (d.data.customerRecover.customerUserErrors.length === 0) {
            callback(true)
            // alert(intl.formatMessage({ id: "pass__text" }))
          } else {
            callback(false)
            // alert(intl.formatMessage({ id: "empty__email" }))
          }
        })
        .catch(err => {
          setuser(prevState => {
            return {
              ...prevState,
              loading: false,
            }
          })
          alert(err)
          console.log(err)
        })
    },
  })

  // Graphql Queries/Mutations
  const [getCustomer, getCustomerData] = useLazyQuery(GET_CUSTOMER, {
    variables: { customerAccessToken: user.customerAccessToken },
    onCompleted: d => {
      if (
        d.customerlastIncompleteCheckout &&
        d.customer.lastIncompleteCheckout.id
      ) {
        const { client } = store
        client.checkout
          .fetch(d.customer.lastIncompleteCheckout.id)
          .then(checkout => {
            setstore(prevState => {
              return { ...prevState, checkout: checkout }
            })
          })
        setCheckoutID(d.customer.lastIncompleteCheckout.id)
      } else if (user.customerAccessToken) {
        addCheckoutToUser({
          variables: {
            checkoutId: getCheckoutID(),
            customerAccessToken: user.customerAccessToken,
          },
        })
      }
      setCustomerDetails("customerData", d.customer)
      setuser(prevState => {
        return { ...prevState, profile: d.customer }
      })
    },
  })

  // States
  const [store, setstore] = useState({
    ...defaultStoreContext,
    addVariantToCart: async (variantId, quantity, attributes, callback) => {
      if (!variantId || variantId === "") {
        console.error("Both a size and quantity are required.")
        return
      }
      setstore(prevState => {
        return { ...prevState, adding: true }
      })

      const { checkout, client } = store
      let checkoutId = getCheckoutID()
      if (checkoutId === undefined || checkoutId === null) {
        checkoutId = await createNewCheckout(client)
      }
      const lineItemsToUpdate = {
        variantId,
        quantity: parseInt(quantity || 1, 10),
        customAttributes: [
          { key: "lang", value: intl.locale },
          {
            key: "currency",
            value: process.env.GATSBY_CURRENCY_TYPE || "eur",
          },
        ],
      }

      if (attributes?.length) {
        lineItemsToUpdate.customAttributes = [
          ...lineItemsToUpdate.customAttributes,
          ...attributes,
        ]
      }

      return client.checkout
        .addLineItems(checkoutId, [lineItemsToUpdate])
        .then(checkout => {
          setstore(prevState => {
            return {
              ...prevState,
              checkout: checkout,
              adding: false,
            }
          })
          if (
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            !getCookie("DiscountCodeApplied")
          ) {
            store.addDiscount(checkoutId, "PLUS")
          }
          // if (
          //   process.env.GATSBY_ACTIVE_ENV === "eur" &&
          //   intl.locale === "fr" &&
          //   getCookie("wlo-codebar-code")
          // ) {
          //   store.addDiscount(checkoutId, getCookie("wlo-codebar-code"))
          // }
          if (
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            getCookie("DiscountCodeApplied")
          ) {
            store.addDiscount(checkoutId, getCookie("DiscountCodeApplied"))
          }
          if (callback) callback(checkout)
        })
        .catch(err => {
          setstore(prevState => {
            return {
              ...prevState,
              adding: false,
            }
          })
          console.log(err)
        })
    },
    addMultipleProductToCart: async (product_arr, attributes, callback) => {
      if (!product_arr || product_arr === "") {
        console.error("Both a size and quantity are required.")
        return
      }
      setstore(prevState => {
        return { ...prevState, adding: true }
      })

      const { checkout, client } = store
      let checkoutId = getCheckoutID()
      if (checkoutId === undefined || checkoutId === null) {
        checkoutId = await createNewCheckout(client)
      }

      let lineItemsArr = []

      product_arr.map(item => {
        let lineItemsToUpdate = {
          variantId: item.variantId,
          quantity: parseInt(item.quantity || 1, 10),
          customAttributes: [
            { key: "lang", value: intl.locale },
            {
              key: "currency",
              value: process.env.GATSBY_CURRENCY_TYPE || "eur",
            },
          ],
        }

        if (attributes?.length) {
          lineItemsToUpdate.customAttributes = [
            ...lineItemsToUpdate.customAttributes,
            ...attributes,
          ]
        }
        lineItemsArr.push(lineItemsToUpdate)
      })

      return client.checkout
        .addLineItems(checkoutId, lineItemsArr)
        .then(checkout => {
          setstore(prevState => {
            return {
              ...prevState,
              checkout: checkout,
              adding: false,
            }
          })
          if (
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            !getCookie("DiscountCodeApplied")
          ) {
            store.addDiscount(checkoutId, "PLUS")
          }
          // if (
          //   process.env.GATSBY_ACTIVE_ENV === "eur" &&
          //   intl.locale === "fr" &&
          //   getCookie("wlo-codebar-code")
          // ) {
          //   store.addDiscount(checkoutId, getCookie("wlo-codebar-code"))
          // }
          if (
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            getCookie("DiscountCodeApplied")
          ) {
            store.addDiscount(checkoutId, getCookie("DiscountCodeApplied"))
          }
          if (callback) callback(checkout)
        })
        .catch(err => {
          setstore(prevState => {
            return {
              ...prevState,
              adding: false,
            }
          })
          console.log(err)
        })
    },
    removeLineItem: async (lineItemID, callback) => {
      const { client } = store
      const checkoutID = getCheckoutID()
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      return await client.checkout
        .removeLineItems(checkoutID, [lineItemID])
        .then(checkout => {
          setstore(prevState => {
            return {
              ...prevState,
              checkout: checkout,
              adding: false,
            }
          })
          if (callback) callback(checkout)
        })
        .catch(err => {
          setstore(prevState => {
            return {
              ...prevState,
              adding: false,
            }
          })
          console.log(err)
        })
    },
    updateLineItem: (client, checkoutID, lineItemID, quantity) => {
      const lineItemsToUpdate = [
        { id: lineItemID, quantity: parseInt(quantity, 10) },
      ]
      setstore(prevState => {
        return { ...prevState, adding: true }
      })

      return client.checkout
        .updateLineItems(checkoutID, lineItemsToUpdate)
        .then(res => {
          setstore(prevState => {
            return {
              ...prevState,
              checkout: res,
              adding: false,
            }
          })
        })
    },
    updateQuantityInCart: (lineItemId, quantity) => {
      const { client } = store
      const checkoutId = getCheckoutID()
      const lineItemsToUpdate = [
        { id: lineItemId, quantity: parseInt(quantity, 10) },
      ]
      setstore(prevState => {
        return { ...prevState, adding: true }
      })

      return client.checkout
        .updateLineItems(checkoutId, lineItemsToUpdate)
        .then(res => {
          if (
            process.env.GATSBY_ACTIVE_ENV === "eur" &&
            intl.locale === "fr" &&
            getCookie("DiscountCodeApplied") &&
            res.discountApplications &&
            res.discountApplications.find(
              discountApplication => discountApplication.code != "WOW-EXCLU"
            ) &&
            res.discountApplications.find(
              discountApplication => discountApplication.code != "EXCLU"
            ) &&
            res.discountApplications.find(
              discountApplication => discountApplication.code != "PERCKOSANTE"
            )
          ) {
            store.addDiscount(checkoutId, getCookie("DiscountCodeApplied"))
          }
          setstore(prevState => {
            return { ...prevState, checkout: res, adding: false }
          })
        })
    },
    shippingAddressUpdate: (checkoutId, shippingAddress) => {
      const { client } = store
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      return client.checkout
        .updateShippingAddress(checkoutId, shippingAddress)
        .then(checkout =>
          setstore(prevState => {
            return { ...prevState, checkout: checkout, adding: false }
          })
        )
    },
    addDiscount: (checkoutId, discountCode, callback) => {
      const { client } = store
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      client.checkout
        .addDiscount(checkoutId, discountCode)
        .then(checkout => {
          if (callback) callback()
          setstore(prevState => {
            return { ...prevState, checkout: checkout, adding: false }
          })
        })
        .catch(err => {
          console.log(err)
          setstore(prevState => {
            return { ...prevState, adding: false }
          })
        })
    },
    removeDiscount: (checkoutId, callback) => {
      const { client } = store
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      client.checkout.removeDiscount(checkoutId).then(checkout => {
        if (callback) callback()
        setstore(prevState => {
          return { ...prevState, checkout: checkout, adding: false }
        })
      })
    },
    applyGiftCard: (checkoutId, giftCardCode, callback) => {
      const { client, addDiscount } = store
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      client.checkout.addGiftCards(checkoutId, giftCardCode).then(checkout => {
        if (checkout.appliedGiftCards && checkout.appliedGiftCards.length > 0)
          // addDiscount(checkoutId, "removediscountswhengiftcard")
          setstore(prevState => {
            return {
              ...prevState,
              checkout: checkout,
              adding: false,
            }
          })
      })
    },
    removeGiftCard: (checkoutId, appliedGiftCardId, callback) => {
      const { client, removeDiscount } = store
      setstore(prevState => {
        return { ...prevState, adding: true }
      })
      removeDiscount(checkoutId, () => {
        client.checkout
          .removeGiftCard(checkoutId, appliedGiftCardId)
          .then(checkout => {
            if (callback) callback()
            setstore(prevState => {
              return {
                ...prevState,
                checkout: checkout,
                adding: false,
              }
            })
          })
      })
    },
  })

  // Initialization Functions
  const setInitialCheckout = client => {
    let checkoutID = getCheckoutID()
    checkoutID
      ? client.checkout.fetch(checkoutID).then(checkout => {
          if (!checkout || checkout.completedAt) {
            client.checkout.create().then(res => {
              setCheckoutID(res.id)
              setstore({ ...store, checkout: res })

              if (user.customerAccessToken) {
                addCheckoutToUser({
                  variables: {
                    checkoutId: res.id,
                    customerAccessToken: user.customerAccessToken,
                  },
                })
              }
            })
          } else {
            if (
              getCookie("DiscountCodeApplied") &&
              checkout.discountApplications &&
              checkout.discountApplications.find(
                discountApplication => discountApplication.code === "PLUS"
              )
            ) {
              store.removeDiscount(checkoutID, () => {
                store.addDiscount(checkoutID, getCookie("DiscountCodeApplied"))
              })
            }
            if (
              !getCookie("DiscountCodeApplied") &&
              checkout.discountApplications &&
              (checkout.discountApplications.find(
                discountApplication => discountApplication.code === "WOW-EXCLU"
              ) ||
                checkout.discountApplications.find(
                  discountApplication => discountApplication.code === "EXCLU"
                ) ||
                checkout.discountApplications.find(
                  discountApplication =>
                    discountApplication.code === "PERCKOSANTE"
                ))
            ) {
              store.removeDiscount(checkoutID)
            }
            setstore({ ...store, checkout: checkout })
          }
        })
      : client.checkout.create().then(res => {
          setCheckoutID(res.id)
          setstore({ ...store, checkout: res })
        })
    setInitialUserInfo()
  }

  const setInitialUserInfo = () => {
    let token = getCustomerDetailsObj("customerAccessToken")
    let custData = getCustomerDetailsObj("customerData")
    token &&
      setuser(prevState => {
        return {
          ...prevState,
          customerAccessToken: token,
        }
      })
    custData &&
      setuser(prevState => {
        return {
          ...prevState,
          profile: custData,
        }
      })
  }

  const createNewCheckout = async client => {
    setstore({ ...store, loading: true })
    const newCheckout = await client.checkout.create()
    setCheckoutID(newCheckout.id)
    setstore({ ...store, checkout: newCheckout, loading: false })
    return newCheckout.id
  }

  const { pathname } = useLocation()

  const waviiHandler = () => {
    if (
      pathname.split("/").includes("cart") ||
      pathname.split("/").includes("panier")
    )
      setisNotCartPage(false)
    else setisNotCartPage(true)

    if (
      pathname.split("/").includes("loop-2") ||
      pathname.split("/").includes("sizes") ||
      pathname.split("/").includes("sizes-men") ||
      pathname.split("/").includes("matelas-hotels-professionnels")
    )
      setisIdealPage(false)
    else setisIdealPage(true)

    if (pathname.split("/").includes("faq")) setisFaqPage(true)
    else setisFaqPage(false)
  }

  const getUserLocale = countryCode => {
    let storeData = countries.filter(
      item => item.country_code === countryCode
    )[0]
    if (!storeData) {
      storeData = countries.filter(item => item.country_code === "FR")[0]
    }
    return storeData
  }

  const hideBanner = () => {
    setcountryPopInHide(true)
  }

  const closeBanner = () => {
    isBrowser && sessionStorage.setItem("CountryPopin", true)
    setcountryPopInHide(true)
  }

  const checkVisibilityBanner = () => {
    if (isBrowser && localStorage.getItem("UserDefaltCountry")) {
      let countryCode = localStorage.getItem("UserDefaltCountry")
      let storeData = getUserLocale(countryCode)
      location.pathname.includes(storeData.store) && setcountryPopInHide(true)
    }
    if (isBrowser) {
      sessionStorage.getItem("CountryPopin") && setcountryPopInHide(true)
    }
  }

  const handleRightStore = () => {
    if (isBrowser) {
      fetch("https://ipapi.co/json/")
        .then(response => {
          return response.json()
        })
        .then(data => {
          let storeData = getUserLocale(data.country_code || "FR")
          setuserCountry(prevState => {
            return {
              ...prevState,
              country: data.country_code || "FR",
              store: storeData.store,
              locale: storeData.locale,
            }
          })
          if (isBrowser && !localStorage.getItem("UserDefaltCountry")) {
            localStorage.setItem("UserDefaltCountry", data.country_code || "FR")
          }
          if (location.pathname.includes(storeData.store)) {
            hideBanner()
          } else {
            setcountryPopInHide(false)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const handleInitialStore = () => {
    let store = "eur"
    if (pathname.includes("/gbp")) store = "gbp"
    else if (pathname.includes("/usd")) store = "usd"
    else if (pathname.includes("/cad")) store = "cad"
    sessionStorage.setItem("selectedCountry", store)
  }

  const handleHtmlLangAttribute = () => {
    let lang = "fr"
    if (pathname.includes("/gbp")) {
      lang = "en-GB"
    } else if (pathname.includes("/usd")) {
      lang = "en-US"
    } else if (pathname.includes("/cad")) {
      if (intl.locale === "fr") {
        lang = "fr-CA"
      } else {
        lang = "en-CA"
      }
    } else {
      if (intl.locale === "es") {
        lang = "es-ES"
      } else if (intl.locale === "de") {
        lang = "de-DE"
      } else if (intl.locale === "en") {
        lang = "en-IE"
      } else {
        lang = "fr"
      }
    }
    return lang
  }

  const AutoApplyDiscount = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    let discountCode = urlParams.get("discount_code")

    if (
      process.env.GATSBY_ACTIVE_ENV === "eur" &&
      intl.locale === "fr" &&
      (discountCode || getCookie("DiscountCodeApplied"))
    ) {
      discountCode &&
        setCookie("DiscountCodeApplied", discountCode.toUpperCase(), 1)

      if (
        getCookie("DiscountCodeApplied") &&
        (getCookie("DiscountCodeApplied").toUpperCase() === "WOW-EXCLU" ||
          getCookie("DiscountCodeApplied").toUpperCase() === "EXCLU")
      ) {
        setisNotLyneupExclusifPage(false)
        setexclusifPromo(getCookie("DiscountCodeApplied").toLowerCase())
        setcountryPopInHide(true)
      }
    }
  }

  useEffect(() => {
    const { client } = store

    isBrowser && AutoApplyDiscount()

    setInitialCheckout(client)

    // Check CountryPopIn Visibility
    // if (isBrowser && !getCookie("DiscountCodeApplied")) {
    //   handleRightStore()
    //   checkVisibilityBanner()
    // } else {
    //   setcountryPopInHide(true)
    // }

    // getting right store details
    handleInitialStore()
  }, [])

  useEffect(() => {
    userInfoEvent(intl.locale)
  }, [user])

  useEffect(() => {
    waviiHandler()
  }, [pathname])

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={user}>
        <StoreContext.Provider value={store}>
          <CountryContext.Provider value={userCountry}>
            <ModalProvider>
              <Fragment>
                <Global
                  styles={css`
                    *,
                    *:before,
                    *:after {
                      box-sizing: inherit;
                    }
                    html {
                      text-rendering: optimizeLegibility;
                      overflow-x: hidden;
                      box-sizing: border-box;
                      -ms-overflow-style: scrollbar;
                      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                    }
                    html,
                    body {
                      width: 100%;
                      height: 100%;
                      margin: 0;
                      padding: 0;
                    }

                    body {
                      display: flex;
                      flex-direction: column;
                      min-height: 100vh;
                    }

                    body.fixed-position {
                      position: fixed;
                    }

                    body.fixed-position .country_detection_popup {
                      display: none;
                    }
                    a {
                      color: ${theme.colors.black.base};
                      transition: color 0.5s;
                      text-decoration: none;
                    }
                    a:hover {
                      text-decoration: none;
                      color: ${theme.colors.black.base};
                    }
                    h1,
                    h2,
                    h3,
                    h4 {
                      font-family: ${theme.fontFamily.heading};
                    }

                    .split-form {
                      display: flex;
                      justify-content: space-between;
                    }

                    input,
                    .StripeElement {
                      display: block;
                      margin: 10px 0 20px 0;
                      padding: 10px 14px;
                      font-size: 1em;
                      font-family: "Source Code Pro", monospace;
                      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
                        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
                      border: 0;
                      outline: 0;
                      border-radius: 4px;
                      background: white;
                    }

                    input::placeholder {
                      color: #aab7c4;
                    }

                    input:focus,
                    .StripeElement--focus {
                      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
                        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
                      -webkit-transition: all 150ms ease;
                      transition: all 150ms ease;
                    }

                    .StripeElement.IdealBankElement,
                    .StripeElement.PaymentRequestButton {
                      padding: 0;
                    }

                    .StripeElement.PaymentRequestButton {
                      margin-bottom: 35px;
                    }

                    .StripeElement.loading {
                      height: 41.6px;
                      opacity: 0.6;
                    }

                    .error {
                      margin-bottom: 20px;
                      color: #eb1c26;
                    }
                    .react-tiny-popover-container {
                      z-index: 5 !important;
                      top: ${countryPopInHide
                        ? "27px !important"
                        : "115px !important"};
                      top: 27px !important;
                      position: fixed !important;
                      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
                    }
                    .popover {
                      z-index: 5 !important;
                      top: ${countryPopInHide
                        ? "100px !important"
                        : "186px !important"};
                      top: 100px !important;
                      position: fixed !important;
                      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
                      @media (max-width: 600px) {
                        display: none;
                      }
                    }
                    .wlo_codebar {
                      display: none !important;
                    }
                    #nprogress {
                      background: rgba(0, 0, 0, 0.5);
                      height: 100%;
                      width: 100%;
                      position: fixed;
                      z-index: 9999;
                    }
                    #nprogress .spinner {
                      transform: translate(-50%, -50%);
                      top: 50% !important;
                      left: 50% !important;
                      right: auto !important;
                    }
                    #nprogress .spinner-icon {
                      border-top-color: #fff !important;
                      border-left-color: #fff !important;
                      width: 40px !important;
                      height: 40px !important;
                    }

                    body.headerSticky .lyneup-sticky-menu,
                    body.headerSticky .sticky_text_box {
                      top: ${intl.locale === "fr" ? "50px" : "48px"};
                    }

                    body.headerSticky .showroom-widget {
                      top: 120px;
                    }

                    @media (max-width: 767px) {
                      body.headerSticky .lyneup-sticky-menu,
                      body.headerSticky .sticky_text_box {
                        top: ${intl.locale === "fr" ? "48px" : "48px"};
                      }
                    }

                    @media (max-width: 600px) {
                      body.headerSticky .lyneup-sticky-menu,
                      body.headerSticky .sticky_text_box {
                        top: ${intl.locale === "fr" ? "48px" : "48px"};
                      }
                    }

                    @media (max-width: 480px) {
                      body.headerSticky .lyneup-sticky-menu,
                      body.headerSticky .sticky_text_box {
                        top: ${intl.locale === "fr" ? "42px" : "43px"};
                      }
                    }

                    @media (max-width: 380px) {
                      body.headerSticky .lyneup-sticky-menu,
                      body.headerSticky .sticky_text_box {
                        top: ${intl.locale === "fr" ? "37px" : "43px"};
                      }
                    }

                    @media (max-width: 360px) {
                      body.headerSticky .lyneup-sticky-menu,
                      body.headerSticky .sticky_text_box {
                        top: ${intl.locale === "fr" ? "37px" : "43px"};
                      }
                    }
                  `}
                />
                <Wrapper>
                  <Helmet
                    htmlAttributes={{
                      lang: handleHtmlLangAttribute(),
                    }}
                  ></Helmet>
                  <HeaderSection
                    className="header_section"
                    exclusifPromo={exclusifPromo}
                    isIdealPage={isIdealPage}
                    isNotCartPage={isNotCartPage}
                    isNotLyneupExclusifPage={isNotLyneupExclusifPage}
                  >
                    {/*{isIdealPage &&
                      isNotLyneupExclusifPage &&
                      !countryPopInHide && (
                        <CountryDetectionPopin
                          hideBanner={hideBanner}
                          closeBanner={closeBanner}
                        />
                      )}*/}
                  </HeaderSection>
                  {/* moved to faq page*/}
                  {/*{(intl.locale === "fr" || intl.locale === "en") &&
                    isFaqPage && <FaqSearchSection />}*/}
                  {children}
                  {process.env.GATSBY_ACTIVE_ENV === "eur" &&
                    intl.locale === "fr" &&
                    isIdealPage &&
                    isNotCartPage &&
                    isNotLyneupExclusifPage && <NewsLetterWeekendSpaPopup />}
                  {process.env.GATSBY_ACTIVE_ENV === "gbp" &&
                    intl.locale === "en" &&
                    isIdealPage &&
                    isNotCartPage &&
                    isNotLyneupExclusifPage && <NewsLetterMultipleStepPopup />}
                </Wrapper>
              </Fragment>
            </ModalProvider>
          </CountryContext.Provider>
        </StoreContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  )
}

export default injectIntl(props => (
  <ApolloProvider client={apolloClient}>
    <Layout {...props} />
  </ApolloProvider>
))
